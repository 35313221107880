import React from 'react'
import band01 from '../Assets/Phylactery-band-01.jpg'
import band02 from '../Assets/Phylactery-band-02.jpg'
import logo01 from '../Assets/Phylactery-logo-01.jpg'
import logo02 from '../Assets/Phylactery-logo-02.jpg'

const Phylactery = () => {
  return (
    <div>
      <h1>Phylactery</h1>
      <p>2014 - 2020</p>
      <img src={band01} />
      <p>
        Etiam commodo dui eget wisi. Ut enim ad minima veniam, quis nostrum exercitationem ullam
        corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Integer
        pellentesque quam vel velit. Aliquam ante. In convallis. Aliquam erat volutpat. Vivamus
        porttitor turpis ac leo. Nulla quis diam. In convallis. Cras elementum. In rutrum. Nulla
        turpis magna, cursus sit amet, suscipit a, interdum id, felis. Mauris dolor felis, sagittis
        at, luctus sed, aliquam non, tellus. Ut enim ad minim veniam, quis nostrud exercitation
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Curabitur vitae diam non enim
        vestibulum interdum. Mauris tincidunt sem sed arcu.
      </p>
      <img src={logo02} width='50%' />
      <p>
        Et harum quidem rerum facilis est et expedita distinctio. Ut enim ad minima veniam, quis
        nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
        consequatur? Phasellus faucibus molestie nisl. Mauris dolor felis, sagittis at, luctus sed,
        aliquam non, tellus. Curabitur sagittis hendrerit ante. Mauris tincidunt sem sed arcu. Nulla
        non lectus sed nisl molestie malesuada. Vivamus luctus egestas leo. Nullam at arcu a est
        sollicitudin euismod. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis
        voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Nulla
        quis diam. Integer imperdiet lectus quis justo. Pellentesque sapien. Sed ut perspiciatis
        unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
        aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta
        sunt explicabo. Morbi leo mi, nonummy eget tristique non, rhoncus non leo. Etiam sapien
        elit, consequat eget, tristique non, venenatis quis, ante. Class aptent taciti sociosqu ad
        litora torquent per conubia nostra, per inceptos hymenaeos. Nunc auctor. Ut tempus purus at
        lorem.
      </p>
      <img src={band02} />
      <p>
        Mauris metus. Nam sed tellus id magna elementum tincidunt. Nullam sit amet magna in magna
        gravida vehicula. Nullam dapibus fermentum ipsum. Aliquam erat volutpat. Etiam quis quam.
        Aliquam erat volutpat. Nullam at arcu a est sollicitudin euismod. Etiam quis quam. Et harum
        quidem rerum facilis est et expedita distinctio. Quisque porta. Praesent dapibus. In rutrum.
        Nunc tincidunt ante vitae massa. Aenean fermentum risus id tortor. Aliquam erat volutpat.
      </p>

      <p>
        Donec quis nibh at felis congue commodo. Nullam sit amet magna in magna gravida vehicula.
        Maecenas aliquet accumsan leo. Duis viverra diam non justo. Pellentesque sapien. Maecenas
        libero. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
        mus. Nam sed tellus id magna elementum tincidunt. Maecenas libero. Maecenas lorem. In enim a
        arcu imperdiet malesuada. Praesent in mauris eu tortor porttitor accumsan. In dapibus augue
        non sapien. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac
        turpis egestas. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Et harum
        quidem rerum facilis est et expedita distinctio. Donec iaculis gravida nulla. Ut tempus
        purus at lorem. Aenean placerat. Maecenas libero.
      </p>
      <img src={logo01} width='50%' />

      <p>
        Suspendisse sagittis ultrices augue. Mauris dolor felis, sagittis at, luctus sed, aliquam
        non, tellus. Donec vitae arcu. Etiam egestas wisi a erat. Nullam at arcu a est sollicitudin
        euismod. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
        fugiat nulla pariatur. Suspendisse nisl. Aenean fermentum risus id tortor. Ut tempus purus
        at lorem. In rutrum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
        veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nullam sit amet magna in
        magna gravida vehicula. Praesent id justo in neque elementum ultrices. Nulla non arcu
        lacinia neque faucibus fringilla. Etiam bibendum elit eget erat. Morbi imperdiet, mauris ac
        auctor dictum, nisl ligula egestas nulla, et sollicitudin sem purus in lacus. Nemo enim
        ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur
        magni dolores eos qui ratione voluptatem sequi nesciunt.
      </p>

      <p>
        Phasellus faucibus molestie nisl. Sed elit dui, pellentesque a, faucibus vel, interdum nec,
        diam. Phasellus rhoncus. Nullam justo enim, consectetuer nec, ullamcorper ac, vestibulum in,
        elit. Aenean id metus id velit ullamcorper pulvinar. Donec ipsum massa, ullamcorper in,
        auctor et, scelerisque sed, est. Etiam neque. Proin pede metus, vulputate nec, fermentum
        fringilla, vehicula vitae, justo. Nullam eget nisl. Fusce wisi.
      </p>
    </div>
  )
}

export default Phylactery
