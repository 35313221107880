import '../App.css'
import React from 'react'
import eightbitiny from '../Assets/Other-8bitiny.jpg'
import erinnor from '../Assets/Other-Erinnor.jpg'
import evolution from '../Assets/Other-Evolution.jpg'
import firstsmile from '../Assets/Other-First-Smile.jpg'
import gloria from '../Assets/Other-Gloria.jpg'
import jcs from '../Assets/Other-JCS.jpg'
import noiseshell from '../Assets/Other-Noiseshell.png'
import vanguard from '../Assets/Other-Vanguard.jpg'

const Other = () => {
  return (
    <div>
      <div>
        <h2 id='noiseshell'>N01535H3ll</h2>
        <p>2018 - ?</p>
        <img src={noiseshell} />
        <p>
          Mauris dolor felis, sagittis at, luctus sed, aliquam non, tellus. Nullam sapien sem,
          ornare ac, nonummy non, lobortis a enim. Etiam dui sem, fermentum vitae, sagittis id,
          malesuada in, quam. In enim a arcu imperdiet malesuada. Proin mattis lacinia justo. Fusce
          suscipit libero eget elit. Duis bibendum, lectus ut viverra rhoncus, dolor nunc faucibus
          libero, eget facilisis enim ipsum id lacus. Morbi leo mi, nonummy eget tristique non,
          rhoncus non leo. Curabitur bibendum justo non orci. Phasellus faucibus molestie nisl.
          Nulla est. Nulla non arcu lacinia neque faucibus fringilla. Mauris elementum mauris vitae
          tortor. Integer lacinia. Nulla non arcu lacinia neque faucibus fringilla. Praesent in
          mauris eu tortor porttitor accumsan. Fusce nibh. Fusce dui leo, imperdiet in, aliquam sit
          amet, feugiat eu, orci. Mauris elementum mauris vitae tortor. Duis viverra diam non justo.
        </p>

        <p>
          Phasellus et lorem id felis nonummy placerat. Fusce tellus. Cras pede libero, dapibus nec,
          pretium sit amet, tempor quis. Integer vulputate sem a nibh rutrum consequat. Nunc
          tincidunt ante vitae massa. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
          reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores
          repellat. Aliquam ornare wisi eu metus. Fusce aliquam vestibulum ipsum. Curabitur vitae
          diam non enim vestibulum interdum. Integer imperdiet lectus quis justo.
        </p>
      </div>
      <div>
        <h2 id='first-smile'>First Smile</h2>
        <p>2016 - 2016</p>
        <img src={firstsmile} />
        <p>
          Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
          hymenaeos. In convallis. Aliquam erat volutpat. Fusce consectetuer risus a nunc. In
          convallis. Nulla est. Maecenas lorem. Nullam rhoncus aliquam metus. Maecenas fermentum,
          sem in pharetra pellentesque, velit turpis volutpat ante, in pharetra metus odio a lectus.
          Mauris suscipit, ligula sit amet pharetra semper, nibh ante cursus purus, vel sagittis
          velit mauris vel metus. Vivamus luctus egestas leo. Nemo enim ipsam voluptatem quia
          voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui
          ratione voluptatem sequi nesciunt. Donec iaculis gravida nulla. Duis ante orci, molestie
          vitae vehicula venenatis, tincidunt ac pede. Etiam ligula pede, sagittis quis, interdum
          ultricies, scelerisque eu. Curabitur ligula sapien, pulvinar a vestibulum quis, facilisis
          vel sapien. Etiam dictum tincidunt diam. In rutrum.
        </p>

        <p>
          Phasellus et lorem id felis nonummy placerat. Maecenas ipsum velit, consectetuer eu
          lobortis ut, dictum at dui. Nullam sit amet magna in magna gravida vehicula. Vestibulum
          fermentum tortor id mi. Pellentesque pretium lectus id turpis. Suspendisse nisl.
          Vestibulum erat nulla, ullamcorper nec, rutrum non, nonummy ac, erat. Integer rutrum, orci
          vestibulum ullamcorper ultricies, lacus quam ultricies odio, vitae placerat pede sem sit
          amet enim. In enim a arcu imperdiet malesuada. Nullam justo enim, consectetuer nec,
          ullamcorper ac, vestibulum in, elit.
        </p>
      </div>
      <div>
        <h2 id='8bitiny'>8BITINY</h2>
        <p>2018 - ?</p>
        <img src={eightbitiny} />
        <p>
          Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
          hymenaeos. Pellentesque ipsum. In convallis. Suspendisse nisl. Nam quis nulla. Mauris
          suscipit, ligula sit amet pharetra semper, nibh ante cursus purus, vel sagittis velit
          mauris vel metus. Suspendisse nisl. Nam quis nulla. Mauris elementum mauris vitae tortor.
          Integer rutrum, orci vestibulum ullamcorper ultricies, lacus quam ultricies odio, vitae
          placerat pede sem sit amet enim. Etiam quis quam. Mauris elementum mauris vitae tortor.
          Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet
          ut et voluptates repudiandae sint et molestiae non recusandae. Pellentesque habitant morbi
          tristique senectus et netus et malesuada fames ac turpis egestas. Integer pellentesque
          quam vel velit. Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam,
          tempor suscipit diam nulla vel leo.
        </p>
      </div>
      <div>
        <h2 id='gloria'>Gloria cantate</h2>
        <p>2013 - 2017</p>
        <img src={gloria} />
        <p>
          Sed convallis magna eu sem. Cum sociis natoque penatibus et magnis dis parturient montes,
          nascetur ridiculus mus. Integer malesuada. Curabitur ligula sapien, pulvinar a vestibulum
          quis, facilisis vel sapien. Donec iaculis gravida nulla. Integer malesuada. Duis ante
          orci, molestie vitae vehicula venenatis, tincidunt ac pede. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos hymenaeos. Etiam sapien elit, consequat
          eget, tristique non, venenatis quis, ante. Et harum quidem rerum facilis est et expedita
          distinctio. Pellentesque sapien.
        </p>

        <p>
          In dapibus augue non sapien. Quis autem vel eum iure reprehenderit qui in ea voluptate
          velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
          nulla pariatur? Aliquam id dolor. Praesent vitae arcu tempor neque lacinia pretium.
          Pellentesque arcu. Aliquam erat volutpat. Et harum quidem rerum facilis est et expedita
          distinctio. Maecenas ipsum velit, consectetuer eu lobortis ut, dictum at dui. Duis ante
          orci, molestie vitae vehicula venenatis, tincidunt ac pede. Excepteur sint occaecat
          cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          Nullam rhoncus aliquam metus. Praesent vitae arcu tempor neque lacinia pretium. Sed elit
          dui, pellentesque a, faucibus vel, interdum nec, diam.
        </p>

        <p>
          Maecenas ipsum velit, consectetuer eu lobortis ut, dictum at dui. Praesent dapibus. Sed ut
          perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
          totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
          vitae dicta sunt explicabo. Phasellus faucibus molestie nisl. In rutrum. Etiam sapien
          elit, consequat eget, tristique non, venenatis quis, ante. Itaque earum rerum hic tenetur
          a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut
          perferendis doloribus asperiores repellat. Curabitur sagittis hendrerit ante. Fusce nibh.
          Pellentesque arcu. Aliquam id dolor. Morbi scelerisque luctus velit. Cum sociis natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nemo enim ipsam
          voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
          dolores eos qui ratione voluptatem sequi nesciunt. Nam quis nulla. Proin in tellus sit
          amet nibh dignissim sagittis. Praesent in mauris eu tortor porttitor accumsan.
        </p>
      </div>
      <div>
        <h2 id='jcs'>Jesus Christ Superstar</h2>
        <p>2016 - 2017</p>
        <img src={jcs} />
        <p>
          Quisque porta. Aenean fermentum risus id tortor. Maecenas ipsum velit, consectetuer eu
          lobortis ut, dictum at dui. Mauris elementum mauris vitae tortor. Aliquam erat volutpat.
          Curabitur ligula sapien, pulvinar a vestibulum quis, facilisis vel sapien. In dapibus
          augue non sapien. Duis ante orci, molestie vitae vehicula venenatis, tincidunt ac pede.
          Etiam dui sem, fermentum vitae, sagittis id, malesuada in, quam. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Integer
          imperdiet lectus quis justo. Nullam faucibus mi quis velit. Fusce tellus. Nunc tincidunt
          ante vitae massa. Duis bibendum, lectus ut viverra rhoncus, dolor nunc faucibus libero,
          eget facilisis enim ipsum id lacus. Etiam bibendum elit eget erat. Fusce tellus odio,
          dapibus id fermentum quis, suscipit id erat. Nulla accumsan, elit sit amet varius semper,
          nulla mauris mollis quam, tempor suscipit diam nulla vel leo.
        </p>

        <p>
          Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
          consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. In sem justo,
          commodo ut, suscipit at, pharetra vitae, orci. Etiam dui sem, fermentum vitae, sagittis
          id, malesuada in, quam. Vestibulum erat nulla, ullamcorper nec, rutrum non, nonummy ac,
          erat. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Phasellus
          rhoncus. Maecenas ipsum velit, consectetuer eu lobortis ut, dictum at dui. Aliquam ante.
          Aenean vel massa quis mauris vehicula lacinia. Proin in tellus sit amet nibh dignissim
          sagittis. Fusce consectetuer risus a nunc. Integer rutrum, orci vestibulum ullamcorper
          ultricies, lacus quam ultricies odio, vitae placerat pede sem sit amet enim. Mauris metus.
          Fusce nibh. Nunc tincidunt ante vitae massa. Fusce dui leo, imperdiet in, aliquam sit
          amet, feugiat eu, orci. Mauris suscipit, ligula sit amet pharetra semper, nibh ante cursus
          purus, vel sagittis velit mauris vel metus. Ut tempus purus at lorem.
        </p>
      </div>
      <div>
        <h2 id='evolution'>Evolution</h2>
        <p>2011 - 2013</p>
        <img src={evolution} />
        <p>
          Mauris elementum mauris vitae tortor. Etiam bibendum elit eget erat. Maecenas
          sollicitudin. Nullam justo enim, consectetuer nec, ullamcorper ac, vestibulum in, elit. Ut
          enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam,
          nisi ut aliquid ex ea commodi consequatur? Morbi scelerisque luctus velit. Curabitur vitae
          diam non enim vestibulum interdum. Aliquam id dolor. Nunc auctor. Class aptent taciti
          sociosqu ad litora torquent per conubia nostra, per inceptos hymenaeos. Sed ac dolor sit
          amet purus malesuada congue. Morbi leo mi, nonummy eget tristique non, rhoncus non leo.
          Maecenas sollicitudin. Donec vitae arcu. Praesent in mauris eu tortor porttitor accumsan.
        </p>

        <p>
          Aliquam erat volutpat. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas
          nulla, et sollicitudin sem purus in lacus. Nulla turpis magna, cursus sit amet, suscipit
          a, interdum id, felis. Nam quis nulla. Fusce suscipit libero eget elit. Praesent id justo
          in neque elementum ultrices. Vestibulum erat nulla, ullamcorper nec, rutrum non, nonummy
          ac, erat. Morbi leo mi, nonummy eget tristique non, rhoncus non leo. Maecenas aliquet
          accumsan leo. Etiam bibendum elit eget erat. Praesent vitae arcu tempor neque lacinia
          pretium. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
          hymenaeos. Curabitur bibendum justo non orci. Nunc tincidunt ante vitae massa. Mauris
          suscipit, ligula sit amet pharetra semper, nibh ante cursus purus, vel sagittis velit
          mauris vel metus. Proin pede metus, vulputate nec, fermentum fringilla, vehicula vitae,
          justo. Maecenas lorem. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
          consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et
          dolore magnam aliquam quaerat voluptatem. Nullam eget nisl.
        </p>
      </div>
      <div>
        <h2 id='erinnor'>Erinnor</h2>
        <p>2013 - 2014</p>
        <img src={erinnor} />
        <p>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
          nulla pariatur. Donec ipsum massa, ullamcorper in, auctor et, scelerisque sed, est.
          Praesent id justo in neque elementum ultrices. Curabitur ligula sapien, pulvinar a
          vestibulum quis, facilisis vel sapien. Duis ante orci, molestie vitae vehicula venenatis,
          tincidunt ac pede. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Morbi
          scelerisque luctus velit. Mauris metus. Suspendisse nisl. Cum sociis natoque penatibus et
          magnis dis parturient montes, nascetur ridiculus mus. Pellentesque habitant morbi
          tristique senectus et netus et malesuada fames ac turpis egestas. Nulla est. Fusce aliquam
          vestibulum ipsum. Proin in tellus sit amet nibh dignissim sagittis. Curabitur bibendum
          justo non orci. In rutrum. Vivamus luctus egestas leo. Etiam commodo dui eget wisi. Etiam
          dictum tincidunt diam. Fusce suscipit libero eget elit.
        </p>

        <p>
          Sed ac dolor sit amet purus malesuada congue. Temporibus autem quibusdam et aut officiis
          debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et
          molestiae non recusandae. Cras elementum. Donec quis nibh at felis congue commodo. Nulla
          est. Donec ipsum massa, ullamcorper in, auctor et, scelerisque sed, est. Praesent id justo
          in neque elementum ultrices. Nullam eget nisl. Fusce tellus odio, dapibus id fermentum
          quis, suscipit id erat. Phasellus faucibus molestie nisl. Aenean fermentum risus id
          tortor. Phasellus faucibus molestie nisl. Duis pulvinar. Curabitur sagittis hendrerit
          ante. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
          adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam
          aliquam quaerat voluptatem. Duis ante orci, molestie vitae vehicula venenatis, tincidunt
          ac pede.
        </p>
      </div>
      <div>
        <h2>MC Kláda</h2>
        <p>2018 - ?</p>
        <p>
          Pellentesque pretium lectus id turpis. Cum sociis natoque penatibus et magnis dis
          parturient montes, nascetur ridiculus mus. Fusce tellus odio, dapibus id fermentum quis,
          suscipit id erat. Etiam posuere lacus quis dolor. In enim a arcu imperdiet malesuada.
          Suspendisse nisl. Nulla non lectus sed nisl molestie malesuada. Fusce consectetuer risus a
          nunc. Nulla quis diam. Integer malesuada. Donec vitae arcu. Integer pellentesque quam vel
          velit. Praesent id justo in neque elementum ultrices. Vivamus luctus egestas leo. Ut enim
          ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut
          aliquid ex ea commodi consequatur? Maecenas libero.
        </p>

        <p>
          Sed ac dolor sit amet purus malesuada congue. Duis condimentum augue id magna semper
          rutrum. Nulla pulvinar eleifend sem. Vestibulum fermentum tortor id mi. Donec iaculis
          gravida nulla. Integer imperdiet lectus quis justo. Temporibus autem quibusdam et aut
          officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint
          et molestiae non recusandae. Etiam dui sem, fermentum vitae, sagittis id, malesuada in,
          quam. Donec ipsum massa, ullamcorper in, auctor et, scelerisque sed, est. Nullam lectus
          justo, vulputate eget mollis sed, tempor sed magna. Vestibulum fermentum tortor id mi. Nam
          sed tellus id magna elementum tincidunt.
        </p>
      </div>
      <div>
        <h2 id='kajot'>Kajot</h2>
        <p>2019 - 2021</p>
        <p>
          Etiam egestas wisi a erat. Suspendisse nisl. Aliquam erat volutpat. Sed vel lectus. Donec
          odio tempus molestie, porttitor ut, iaculis quis, sem. Nullam justo enim, consectetuer
          nec, ullamcorper ac, vestibulum in, elit. Fusce suscipit libero eget elit. Proin pede
          metus, vulputate nec, fermentum fringilla, vehicula vitae, justo. Etiam dictum tincidunt
          diam. Duis sapien nunc, commodo et, interdum suscipit, sollicitudin et, dolor. Curabitur
          ligula sapien, pulvinar a vestibulum quis, facilisis vel sapien. Et harum quidem rerum
          facilis est et expedita distinctio. Ut tempus purus at lorem. Etiam ligula pede, sagittis
          quis, interdum ultricies, scelerisque eu. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. In enim a arcu
          imperdiet malesuada. Etiam dui sem, fermentum vitae, sagittis id, malesuada in, quam.
          Mauris dolor felis, sagittis at, luctus sed, aliquam non, tellus. Phasellus rhoncus.
          Integer in sapien. Aliquam erat volutpat.
        </p>

        <p>
          Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
          Suspendisse sagittis ultrices augue. Integer rutrum, orci vestibulum ullamcorper
          ultricies, lacus quam ultricies odio, vitae placerat pede sem sit amet enim. Etiam egestas
          wisi a erat. Etiam dictum tincidunt diam. Pellentesque pretium lectus id turpis.
          Pellentesque sapien. Maecenas libero. Integer tempor. Pellentesque ipsum. Aliquam id
          dolor. Fusce suscipit libero eget elit.
        </p>
      </div>
      <div>
        <h2>VŠ</h2>
        <p>2017 - today</p>
        <p>
          Maecenas lorem. Etiam dictum tincidunt diam. Vivamus porttitor turpis ac leo. Sed ut
          perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
          totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
          vitae dicta sunt explicabo. Nunc tincidunt ante vitae massa. Nulla est. Integer lacinia.
          Morbi scelerisque luctus velit. Nullam lectus justo, vulputate eget mollis sed, tempor sed
          magna. Nullam at arcu a est sollicitudin euismod. Temporibus autem quibusdam et aut
          officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint
          et molestiae non recusandae. In rutrum. Morbi imperdiet, mauris ac auctor dictum, nisl
          ligula egestas nulla, et sollicitudin sem purus in lacus. Donec quis nibh at felis congue
          commodo.
        </p>
      </div>
      <div>
        <h2>Šuplíkové projekty</h2>
        <p>
          Integer vulputate sem a nibh rutrum consequat. Phasellus et lorem id felis nonummy
          placerat. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus
          mus. Mauris suscipit, ligula sit amet pharetra semper, nibh ante cursus purus, vel
          sagittis velit mauris vel metus. Cum sociis natoque penatibus et magnis dis parturient
          montes, nascetur ridiculus mus. Quis autem vel eum iure reprehenderit qui in ea voluptate
          velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
          nulla pariatur? Nunc tincidunt ante vitae massa. Nulla turpis magna, cursus sit amet,
          suscipit a, interdum id, felis. Vestibulum fermentum tortor id mi. Mauris tincidunt sem
          sed arcu. Nullam sapien sem, ornare ac, nonummy non, lobortis a enim. Nulla est. Curabitur
          vitae diam non enim vestibulum interdum. Sed ac dolor sit amet purus malesuada congue.
          Mauris suscipit, ligula sit amet pharetra semper, nibh ante cursus purus, vel sagittis
          velit mauris vel metus.
        </p>
      </div>
      <div>
        <h2>Záskoky</h2>
        <p>
          Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla, et sollicitudin sem
          purus in lacus. Phasellus rhoncus. Sed vel lectus. Donec odio tempus molestie, porttitor
          ut, iaculis quis, sem. Mauris suscipit, ligula sit amet pharetra semper, nibh ante cursus
          purus, vel sagittis velit mauris vel metus.
        </p>
        <h3>Vanguard</h3>
        <img src={vanguard} />
        <p>
          Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit
          diam nulla vel leo. Aliquam ante. Integer malesuada. Praesent vitae arcu tempor neque
          lacinia pretium. Nullam dapibus fermentum ipsum. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Vivamus porttitor
          turpis ac leo. Aliquam in lorem sit amet leo accumsan lacinia. Aliquam ante. Suspendisse
          sagittis ultrices augue.
        </p>
        <h3>Flower power</h3>
        <p>
          Nulla est. Nullam at arcu a est sollicitudin euismod. Fusce wisi. Pellentesque habitant
          morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi leo mi,
          nonummy eget tristique non, rhoncus non leo. Ut tempus purus at lorem. Nunc auctor. Morbi
          scelerisque luctus velit. Etiam dui sem, fermentum vitae, sagittis id, malesuada in, quam.
          Fusce tellus. Curabitur bibendum justo non orci. Etiam ligula pede, sagittis quis,
          interdum ultricies, scelerisque eu. Fusce wisi.
        </p>
      </div>
    </div>
  )
}

export default Other
