import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { Link, BrowserRouter as Router } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import styled from 'styled-components'

const NavLink = styled(Link)`
  padding: 20px;
  color: white;
  text-decoration: none;
  &:hover {
    color: #ffffff;
    background: #2e2e2e;
  }
`

const CustomBar = styled(Toolbar)`
  background: black;
`
const CustomButton = styled(Button)`
  padding: 20px;
  color: white;
  text-decoration: none;
  &:hover {
    color: #ffffff;
    background: #2e2e2e;
  }
`
const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <AppBar position='static'>
      <CustomBar>
        <NavLink to='/'>Home</NavLink>
        <NavLink to='/jls'>Jake Loves Space</NavLink>
        <NavLink to='/phylactery'>Phylactery</NavLink>
        <NavLink to='/shotc'>Shot-C</NavLink>
        <NavLink to='/aeronaut'>Aeronaut</NavLink>
        <NavLink to='/kabaret'>Kabaret Dr. Caligariho</NavLink>
        <div color='white'>
          <CustomButton
            color='inherit'
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleClick}
          >
            ...
          </CustomButton>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <a href='/other#noiseshell'>
              <MenuItem onClick={handleClose}>N01535H3ll</MenuItem>
            </a>

            <a href='/other#first-smile'>
              <MenuItem onClick={handleClose}>First Smile</MenuItem>
            </a>
            <a href='/other#8bitiny'>
              <MenuItem onClick={handleClose}>8BITINY</MenuItem>
            </a>

            <a href='/other#gloria'>
              <MenuItem onClick={handleClose}>Gloria cantate</MenuItem>
            </a>

            <a href='/other#jcs'>
              <MenuItem onClick={handleClose}>Jesus Christ Superstar</MenuItem>
            </a>
            <a href='/other#evolution'>
              <MenuItem onClick={handleClose}>Evolution</MenuItem>
            </a>
            <a href='/other#erinnor'>
              <MenuItem onClick={handleClose}>Erinnor</MenuItem>
            </a>
            <a href='/other#kajot'>
              <MenuItem onClick={handleClose}>More</MenuItem>
            </a>
          </Menu>
        </div>
      </CustomBar>
    </AppBar>
  )
}

export default Navbar
