import { AppBar, Button, IconButton, Toolbar, Typography } from '@material-ui/core'
import { Link, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Aeronaut from './Pages/Aeronaut'
import Home from './Pages/Home'
import JLS from './Pages/JLS'
import Kabaret from './Pages/Kabaret'
import Navbar from './Components/Navbar'
import Other from './Pages/Other'
import Phylactery from './Pages/Phylactery'
import React from 'react'
import Shotc from './Pages/Shotc'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 20%;
  padding-right: 20%;
`

export default function App() {
  return (
    <Router>
      <Navbar />
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Wrapper>
        <Switch>
          <Route path='/jls'>
            <JLS />
          </Route>
          <Route path='/phylactery'>
            <Phylactery />
          </Route>
          <Route path='/shotc'>
            <Shotc />
          </Route>
          <Route path='/aeronaut'>
            <Aeronaut />
          </Route>
          <Route path='/kabaret'>
            <Kabaret />
          </Route>
          <Route path='/other'>
            <Other />
          </Route>
          <Route path='/'>
            <Home />
          </Route>
        </Switch>
      </Wrapper>
    </Router>
  )
}
