import { Grid, Typography } from '@material-ui/core'
import React from 'react'

const Home = () => {
  return (
    <Grid container>
      <Typography>
        <h1>Welcome to my music blog!</h1>
        <p>
          Welcome to my blog where I would like to dive deep into my music career which these days
          has been reduced to a minor part of my life but which at one point was a passion on which
          I have spent all my free time. I would like to look back at the projects I&apos;ve been
          fortunate enough to be a part of as well as music I made on my own. The texts may get be
          written some day and all of these are actual projects I&apos;ve been a part of but right
          now getting the blog right from the programming perspective is my main focus.
        </p>
        <h2>How it all started</h2>
        <span>
          To this day I find it unusual how much has music shaped my life and my journey through my
          musician period gave me some of the best memories of my life. I haven&apos;t palyed an
          instrument as a child and I was actually never really interested in music until I found
          heavy metal when I was nine years old. Something then just instantly clicked and things
          were never the same. My favorite band at the time was Lordi - a finnish hard-rock band
          which had just won the Eurovision in 2006. They dressed like monsters and the music seemed
          really heavy to me and I loved it.
        </span>
        <h2>Lorem ipsum</h2>
        <p>
          Maecenas ipsum velit, consectetuer eu lobortis ut, dictum at dui. Duis viverra diam non
          justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
          hymenaeos. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas nulla, et
          sollicitudin sem purus in lacus. Vivamus luctus egestas leo. Fusce suscipit libero eget
          elit. Maecenas libero. Integer lacinia. Morbi scelerisque luctus velit. Nunc tincidunt
          ante vitae massa. Praesent id justo in neque elementum ultrices. Nullam rhoncus aliquam
          metus. In laoreet, magna id viverra tincidunt, sem odio bibendum justo, vel imperdiet
          sapien wisi sed libero. Pellentesque arcu. Duis pulvinar. In dapibus augue non sapien.
        </p>
        <p>
          Nulla non arcu lacinia neque faucibus fringilla. Nam quis nulla. In dapibus augue non
          sapien. Vivamus luctus egestas leo. Mauris dolor felis, sagittis at, luctus sed, aliquam
          non, tellus. Maecenas aliquet accumsan leo. Etiam neque. Cum sociis natoque penatibus et
          magnis dis parturient montes, nascetur ridiculus mus. Aliquam erat volutpat. Mauris dolor
          felis, sagittis at, luctus sed, aliquam non, tellus. Nulla non arcu lacinia neque faucibus
          fringilla. Fusce suscipit libero eget elit. Aliquam ornare wisi eu metus. Curabitur
          bibendum justo non orci. Aenean fermentum risus id tortor.
        </p>
        <h2>Nulla accumsan</h2>
        <p>
          Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor suscipit
          diam nulla vel leo. Aenean vel massa quis mauris vehicula lacinia. Mauris elementum mauris
          vitae tortor. Duis bibendum, lectus ut viverra rhoncus, dolor nunc faucibus libero, eget
          facilisis enim ipsum id lacus. Vestibulum fermentum tortor id mi. Duis risus. Nulla turpis
          magna, cursus sit amet, suscipit a, interdum id, felis. Fusce wisi. Et harum quidem rerum
          facilis est et expedita distinctio. Fusce consectetuer risus a nunc. Duis condimentum
          augue id magna semper rutrum. Mauris tincidunt sem sed arcu. Nulla turpis magna, cursus
          sit amet, suscipit a, interdum id, felis.
        </p>
        <p>
          Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
          hymenaeos. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
          quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Aliquam erat
          volutpat. Integer lacinia. Integer malesuada. Praesent in mauris eu tortor porttitor
          accumsan. Donec iaculis gravida nulla. In rutrum. Temporibus autem quibusdam et aut
          officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint
          et molestiae non recusandae. Proin pede metus, vulputate nec, fermentum fringilla,
          vehicula vitae, justo. Duis sapien nunc, commodo et, interdum suscipit, sollicitudin et,
          dolor. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
          ex ea commodo consequat. Praesent vitae arcu tempor neque lacinia pretium. Cras elementum.
          Etiam bibendum elit eget erat.
        </p>
        <h2>Suspendisse sagittis</h2>
        <p>
          Suspendisse sagittis ultrices augue. Quisque porta. Etiam egestas wisi a erat. Sed ut
          perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
          totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
          vitae dicta sunt explicabo. Nulla accumsan, elit sit amet varius semper, nulla mauris
          mollis quam, tempor suscipit diam nulla vel leo. Aliquam id dolor. Nulla quis diam. Sed ut
          perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
          totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
          vitae dicta sunt explicabo. Curabitur ligula sapien, pulvinar a vestibulum quis, facilisis
          vel sapien. Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam
          nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
          Fusce nibh. Aliquam ante. Duis pulvinar. Aliquam ornare wisi eu metus. Nunc auctor.
          Pellentesque arcu. Mauris tincidunt sem sed arcu.
        </p>
        <p>
          Integer malesuada. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
          fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
          Nullam at arcu a est sollicitudin euismod. Itaque earum rerum hic tenetur a sapiente
          delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis
          doloribus asperiores repellat. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
          Integer malesuada. Aliquam id dolor. Nulla est. Duis risus. Pellentesque ipsum. Etiam
          ligula pede, sagittis quis, interdum ultricies, scelerisque eu. Ut tempus purus at lorem.
          Phasellus rhoncus. Etiam commodo dui eget wisi. Vestibulum erat nulla, ullamcorper nec,
          rutrum non, nonummy ac, erat. Sed ac dolor sit amet purus malesuada congue. Fusce nibh.
          Nam sed tellus id magna elementum tincidunt. Aliquam ante.
        </p>
      </Typography>
    </Grid>
  )
}

export default Home
