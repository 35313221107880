import React from 'react'
import ReactMarkdown from 'react-markdown'
import band01 from '../Assets/Kabaret-band-01.jpg'
import band02 from '../Assets/Kabaret-band-02.jpg'
import band03 from '../Assets/Kabaret-band-03.jpg'
import band04 from '../Assets/Kabaret-band-04.jpg'

const Kabaret = () => {
  return (
    <div>
      <h1>Kabaret Doktora Caligariho</h1>
      <p>2016 - 2019</p>
      <img src={band01} />
      <p>
        Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores
        alias consequatur aut perferendis doloribus asperiores repellat. Morbi imperdiet, mauris ac
        auctor dictum, nisl ligula egestas nulla, et sollicitudin sem purus in lacus. Aenean
        placerat. Proin in tellus sit amet nibh dignissim sagittis. Mauris dolor felis, sagittis at,
        luctus sed, aliquam non, tellus. Praesent id justo in neque elementum ultrices. Ut tempus
        purus at lorem. Et harum quidem rerum facilis est et expedita distinctio. Nam sed tellus id
        magna elementum tincidunt. Etiam dictum tincidunt diam. Phasellus faucibus molestie nisl.
        Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Praesent id justo in neque
        elementum ultrices. Nulla pulvinar eleifend sem. Nullam feugiat, turpis at pulvinar
        vulputate, erat libero tristique tellus, nec bibendum odio risus sit amet ante. Mauris
        metus.
      </p>
      <img src={band02} />
      <p>
        Etiam posuere lacus quis dolor. Aenean id metus id velit ullamcorper pulvinar. Nulla non
        arcu lacinia neque faucibus fringilla. Integer vulputate sem a nibh rutrum consequat.
        Aliquam in lorem sit amet leo accumsan lacinia. Class aptent taciti sociosqu ad litora
        torquent per conubia nostra, per inceptos hymenaeos. Fusce tellus. Ut enim ad minima veniam,
        quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea
        commodi consequatur? Etiam neque. Donec vitae arcu. Etiam dui sem, fermentum vitae, sagittis
        id, malesuada in, quam. Fusce tellus odio, dapibus id fermentum quis, suscipit id erat.
        Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
        velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam
        quaerat voluptatem. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec iaculis
        gravida nulla. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
        laboriosam, nisi ut aliquid ex ea commodi consequatur?
      </p>
      <img src={band03} />
      <p>
        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
        consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Mauris elementum
        mauris vitae tortor. Integer imperdiet lectus quis justo. In sem justo, commodo ut, suscipit
        at, pharetra vitae, orci. Mauris tincidunt sem sed arcu. Aliquam erat volutpat. Ut enim ad
        minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut
        aliquid ex ea commodi consequatur? Maecenas fermentum, sem in pharetra pellentesque, velit
        turpis volutpat ante, in pharetra metus odio a lectus. Mauris tincidunt sem sed arcu. Ut
        tempus purus at lorem. Fusce tellus. Etiam dui sem, fermentum vitae, sagittis id, malesuada
        in, quam. Cras pede libero, dapibus nec, pretium sit amet, tempor quis.
      </p>
      <img src={band04} width='50%' />
      <p>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
        laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
        architecto beatae vitae dicta sunt explicabo. Aliquam id dolor. Aliquam ante. Pellentesque
        sapien. Mauris elementum mauris vitae tortor. Nulla quis diam. Sed convallis magna eu sem.
        Aliquam ornare wisi eu metus. Praesent vitae arcu tempor neque lacinia pretium. Nullam
        dapibus fermentum ipsum. Pellentesque pretium lectus id turpis. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Nullam feugiat, turpis at pulvinar vulputate, erat libero tristique tellus, nec bibendum
        odio risus sit amet ante. Integer pellentesque quam vel velit. Mauris metus. Aliquam erat
        volutpat. Donec vitae arcu. Nam libero tempore, cum soluta nobis est eligendi optio cumque
        nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda
        est, omnis dolor repellendus. Suspendisse sagittis ultrices augue.
      </p>

      <p>
        Aenean placerat. Aliquam ante. Curabitur vitae diam non enim vestibulum interdum. Praesent
        vitae arcu tempor neque lacinia pretium. Vivamus ac leo pretium faucibus. Nulla non arcu
        lacinia neque faucibus fringilla. Fusce tellus. Pellentesque ipsum. Etiam dictum tincidunt
        diam. Maecenas sollicitudin. Ut enim ad minima veniam, quis nostrum exercitationem ullam
        corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Phasellus rhoncus.
        Nam quis nulla. Donec iaculis gravida nulla. Maecenas libero. Praesent vitae arcu tempor
        neque lacinia pretium. Nullam lectus justo, vulputate eget mollis sed, tempor sed magna.
        Nullam faucibus mi quis velit.
      </p>

      <p>
        Maecenas lorem. Ut tempus purus at lorem. Donec vitae arcu. Integer lacinia. Aliquam erat
        volutpat. Nunc dapibus tortor vel mi dapibus sollicitudin. Integer imperdiet lectus quis
        justo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
        Praesent id justo in neque elementum ultrices. Aliquam erat volutpat. Nullam lectus justo,
        vulputate eget mollis sed, tempor sed magna. Nullam dapibus fermentum ipsum. Pellentesque
        ipsum. Integer vulputate sem a nibh rutrum consequat. Nullam dapibus fermentum ipsum. Aenean
        placerat. Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor
        suscipit diam nulla vel leo. Maecenas aliquet accumsan leo. Aliquam erat volutpat.
      </p>

      <p>
        Nulla turpis magna, cursus sit amet, suscipit a, interdum id, felis. Etiam quis quam. Duis
        pulvinar. Donec iaculis gravida nulla. Vivamus porttitor turpis ac leo. Duis ante orci,
        molestie vitae vehicula venenatis, tincidunt ac pede. Praesent vitae arcu tempor neque
        lacinia pretium. Suspendisse nisl. Duis sapien nunc, commodo et, interdum suscipit,
        sollicitudin et, dolor. Cras elementum. Etiam sapien elit, consequat eget, tristique non,
        venenatis quis, ante. Mauris tincidunt sem sed arcu. Ut enim ad minima veniam, quis nostrum
        exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
        consequatur? Nulla accumsan, elit sit amet varius semper, nulla mauris mollis quam, tempor
        suscipit diam nulla vel leo. Neque porro quisquam est, qui dolorem ipsum quia dolor sit
        amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore
        et dolore magnam aliquam quaerat voluptatem. Integer in sapien.
      </p>
    </div>
  )
}

export default Kabaret
