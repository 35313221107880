import '../App.css'
import React from 'react'
import band01 from '../Assets/Shotc-band-01.jpg'
import band02 from '../Assets/Shotc-band-02.jpg'
import band03 from '../Assets/Shotc-band-03.jpg'
import band04 from '../Assets/Shotc-band-04.jpg'

const Shotc = () => {
  return (
    <div>
      <h1>Shot-C</h1>
      <p>2015 - 2017</p>
      <img src={band01} />
      <span>
        <p>
          Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Nunc tincidunt ante
          vitae massa. Duis ante orci, molestie vitae vehicula venenatis, tincidunt ac pede. Fusce
          suscipit libero eget elit. Duis condimentum augue id magna semper rutrum. Aliquam erat
          volutpat. Donec quis nibh at felis congue commodo. Itaque earum rerum hic tenetur a
          sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut
          perferendis doloribus asperiores repellat. Integer vulputate sem a nibh rutrum consequat.
          Etiam commodo dui eget wisi. Cum sociis natoque penatibus et magnis dis parturient montes,
          nascetur ridiculus mus. Etiam egestas wisi a erat. Etiam neque. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Aenean
          vel massa quis mauris vehicula lacinia. Vivamus porttitor turpis ac leo. In enim a arcu
          imperdiet malesuada. Duis condimentum augue id magna semper rutrum. Aenean placerat.
        </p>
        <img src={band02} />
        <p>
          Duis ante orci, molestie vitae vehicula venenatis, tincidunt ac pede. Cras pede libero,
          dapibus nec, pretium sit amet, tempor quis. Etiam neque. Aliquam ante. Sed ac dolor sit
          amet purus malesuada congue. Nulla turpis magna, cursus sit amet, suscipit a, interdum id,
          felis. In dapibus augue non sapien. Aliquam erat volutpat. Vivamus luctus egestas leo.
          Etiam neque. Aliquam id dolor. Sed vel lectus. Donec odio tempus molestie, porttitor ut,
          iaculis quis, sem. Curabitur sagittis hendrerit ante.
        </p>
        <img src={band03} />
      </span>
      <span>
        <p>
          Sed vel lectus. Donec odio tempus molestie, porttitor ut, iaculis quis, sem. Nullam
          faucibus mi quis velit. Integer rutrum, orci vestibulum ullamcorper ultricies, lacus quam
          ultricies odio, vitae placerat pede sem sit amet enim. Nam libero tempore, cum soluta
          nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere
          possimus, omnis voluptas assumenda est, omnis dolor repellendus. Aenean id metus id velit
          ullamcorper pulvinar. Donec iaculis gravida nulla. Aliquam erat volutpat. Nullam faucibus
          mi quis velit. Phasellus et lorem id felis nonummy placerat. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus.
        </p>
        <img src={band04} />
        <p>
          Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id
          quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor
          repellendus. Vestibulum fermentum tortor id mi. Etiam dictum tincidunt diam. Vivamus ac
          leo pretium faucibus. Etiam egestas wisi a erat. Ut tempus purus at lorem. Proin pede
          metus, vulputate nec, fermentum fringilla, vehicula vitae, justo. Morbi imperdiet, mauris
          ac auctor dictum, nisl ligula egestas nulla, et sollicitudin sem purus in lacus. Nunc
          auctor. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
          quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
        </p>
      </span>
    </div>
  )
}

export default Shotc
