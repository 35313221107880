import '../App.css'
import React from 'react'
import band01 from '../Assets/JLS-01.jpg'
import band02 from '../Assets/JLS-band-02.jpg'
import band03 from '../Assets/JLS-band-03.jpg'
import moon from '../Assets/JLS-mesic.png'

const JLS = () => {
  return (
    <div>
      <h1>Jake Loves Space</h1>
      <p>2017 - today</p>
      <span>
        <img src={band01} />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Integer in sapien. Maecenas ipsum
        velit, consectetuer eu lobortis ut, dictum at dui. Aliquam in lorem sit amet leo accumsan
        lacinia. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
        laboriosam, nisi ut aliquid ex ea commodi consequatur? Duis risus. Aliquam ornare wisi eu
        metus. Etiam posuere lacus quis dolor. Integer malesuada. Nulla accumsan, elit sit amet
        varius semper, nulla mauris mollis quam, tempor suscipit diam nulla vel leo. Mauris metus.
        Integer lacinia. Aliquam ornare wisi eu metus. Integer pellentesque quam vel velit.
        Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Proin mattis lacinia
        justo. Donec ipsum massa, ullamcorper in, auctor et, scelerisque sed, est. Duis viverra diam
        non justo. Aenean id metus id velit ullamcorper pulvinar.
      </span>
      <p>
        Duis condimentum augue id magna semper rutrum. Pellentesque pretium lectus id turpis. Etiam
        ligula pede, sagittis quis, interdum ultricies, scelerisque eu. Class aptent taciti sociosqu
        ad litora torquent per conubia nostra, per inceptos hymenaeos. Aenean fermentum risus id
        tortor. Nam quis nulla. Suspendisse sagittis ultrices augue. Maecenas libero. Nulla turpis
        magna, cursus sit amet, suscipit a, interdum id, felis. Aenean placerat. Fusce consectetuer
        risus a nunc. Nullam faucibus mi quis velit. Praesent vitae arcu tempor neque lacinia
        pretium.
      </p>
      <img src={band02} />
      <p>
        Aenean placerat. Cras pede libero, dapibus nec, pretium sit amet, tempor quis. Duis ante
        orci, molestie vitae vehicula venenatis, tincidunt ac pede. Curabitur ligula sapien,
        pulvinar a vestibulum quis, facilisis vel sapien. Maecenas sollicitudin. Nam quis nulla.
        Nulla non lectus sed nisl molestie malesuada. In enim a arcu imperdiet malesuada. Nullam at
        arcu a est sollicitudin euismod. Vestibulum fermentum tortor id mi. Vivamus luctus egestas
        leo. Aliquam erat volutpat. Morbi imperdiet, mauris ac auctor dictum, nisl ligula egestas
        nulla, et sollicitudin sem purus in lacus. Suspendisse sagittis ultrices augue. Etiam
        posuere lacus quis dolor. Donec vitae arcu. Maecenas sollicitudin. Curabitur sagittis
        hendrerit ante.
      </p>
      <img src={band03} />
      <p>
        Mauris dolor felis, sagittis at, luctus sed, aliquam non, tellus. Sed ut perspiciatis unde
        omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
        eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
        explicabo. Phasellus enim erat, vestibulum vel, aliquam a, posuere eu, velit. Praesent in
        mauris eu tortor porttitor accumsan. Mauris dolor felis, sagittis at, luctus sed, aliquam
        non, tellus. Maecenas libero. Nulla non lectus sed nisl molestie malesuada. Aenean id metus
        id velit ullamcorper pulvinar. Cras pede libero, dapibus nec, pretium sit amet, tempor quis.
        Aenean vel massa quis mauris vehicula lacinia.
      </p>
      <p>
        Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores
        alias consequatur aut perferendis doloribus asperiores repellat. Nullam rhoncus aliquam
        metus. Donec quis nibh at felis congue commodo. Duis risus. Praesent dapibus. Lorem ipsum
        dolor sit amet, consectetuer adipiscing elit. Fusce nibh. In sem justo, commodo ut, suscipit
        at, pharetra vitae, orci. Cras pede libero, dapibus nec, pretium sit amet, tempor quis.
        Aliquam in lorem sit amet leo accumsan lacinia. Proin pede metus, vulputate nec, fermentum
        fringilla, vehicula vitae, justo. Etiam sapien elit, consequat eget, tristique non,
        venenatis quis, ante. Mauris dolor felis, sagittis at, luctus sed, aliquam non, tellus. Duis
        sapien nunc, commodo et, interdum suscipit, sollicitudin et, dolor.
      </p>
      <img className='App-logo' alt='logo' src={moon} height='500px' width='500px' />
    </div>
  )
}

export default JLS
