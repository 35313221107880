import React from 'react'
import band01 from '../Assets/Aeronaut-band-01.jpg'
import band02 from '../Assets/Aeronaut-band-02.jpg'
import logo from '../Assets/Aeronaut-profile-01.jpg'

const Aeronaut = () => {
  return (
    <div>
      <h1>Aeronaut</h1>
      <p>2014 - 2017</p>
      <img src={band01} />
      <div>
        <p>
          Aenean id metus id velit ullamcorper pulvinar. Maecenas lorem. Etiam dictum tincidunt
          diam. Sed ac dolor sit amet purus malesuada congue. Ut tempus purus at lorem. Curabitur
          vitae diam non enim vestibulum interdum. Fusce consectetuer risus a nunc. Nulla pulvinar
          eleifend sem. Integer vulputate sem a nibh rutrum consequat. Pellentesque ipsum. In
          convallis. Fusce aliquam vestibulum ipsum. Aliquam ante. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos hymenaeos. Nunc tincidunt ante vitae
          massa. Donec ipsum massa, ullamcorper in, auctor et, scelerisque sed, est.
        </p>
        <img src={logo} />
        <p>
          Aliquam erat volutpat. Sed convallis magna eu sem. Nam quis nulla. Integer tempor.
          Pellentesque pretium lectus id turpis. Nullam justo enim, consectetuer nec, ullamcorper
          ac, vestibulum in, elit. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Mauris dolor felis, sagittis at, luctus sed,
          aliquam non, tellus. Nullam dapibus fermentum ipsum. Donec quis nibh at felis congue
          commodo. Vivamus ac leo pretium faucibus. Etiam ligula pede, sagittis quis, interdum
          ultricies, scelerisque eu. Duis bibendum, lectus ut viverra rhoncus, dolor nunc faucibus
          libero, eget facilisis enim ipsum id lacus. Donec vitae arcu. Etiam bibendum elit eget
          erat. Donec iaculis gravida nulla.
        </p>
        <img src={band02} />
        <p>
          Sed elit dui, pellentesque a, faucibus vel, interdum nec, diam. Phasellus faucibus
          molestie nisl. Nullam sapien sem, ornare ac, nonummy non, lobortis a enim. Suspendisse
          sagittis ultrices augue. Fusce wisi. Sed vel lectus. Donec odio tempus molestie, porttitor
          ut, iaculis quis, sem. Aliquam erat volutpat. Fusce consectetuer risus a nunc. Duis sapien
          nunc, commodo et, interdum suscipit, sollicitudin et, dolor. Maecenas aliquet accumsan
          leo.
        </p>
      </div>
    </div>
  )
}

export default Aeronaut
